var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-select',{attrs:{"filterable":_vm.filterable,"options":_vm.options,"label":_vm.label,"value":_vm.myValue,"multiple":_vm.multiple,"closeOnSelect":_vm.closeOnSelect},on:{"search":_vm.fetchOptions,"open":_vm.onOpen,"input":_vm.updateValue},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var id = ref.id;
var insurance_provider = ref.insurance_provider;
var expire_at = ref.expire_at;
return [_vm._v(" #"+_vm._s(id)+" "+_vm._s(insurance_provider.name)+" "),(expire_at)?_c('div',{staticClass:"badge badge-light"},[_vm._v(" "+_vm._s(_vm._f("fulldate")(expire_at))+" ")]):_vm._e()]}},{key:"option",fn:function(ref){
var id = ref.id;
var insurance_provider = ref.insurance_provider;
var expire_at = ref.expire_at;
return [_vm._v(" #"+_vm._s(id)+" "+_vm._s(insurance_provider.name)+" "),(expire_at)?_c('div',{staticClass:"badge badge-light"},[_vm._v(" expires at "+_vm._s(_vm._f("fulldate")(expire_at))+" ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }