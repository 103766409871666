<template>
    <div>
        <v-select
            @search="fetchOptions"
            @open="onOpen"
            :filterable='filterable'
            :options="options"
            :label='label'
            :value='myValue'
            v-on:input='updateValue'
            :multiple='multiple'
            :closeOnSelect='closeOnSelect'
        >

            <template #selected-option="{ id, insurance_provider, expire_at}">
               #{{ id }} {{ insurance_provider.name }} 
               <div v-if='expire_at' class='badge badge-light'>
               {{ expire_at | fulldate }}
               </div>
            </template>
            <template #option="{ id, insurance_provider, expire_at}">
               #{{ id }} {{ insurance_provider.name }} 
               <div v-if='expire_at' class='badge badge-light'>
               expires at {{ expire_at | fulldate }}
               </div>
            </template>
        </v-select>
    </div>
</template>

<script>
import BaseRemoteSelect from './BaseRemoteSelect.vue';

export default {
    props: ['patientId', 'filter'],
    extends: BaseRemoteSelect,
    data: function() {
        return {
            label: 'id'
        }
    },
    methods: {
        load(apiClient, search, cancelToken) {
            var filter = {
                ... this.filter,
                patient_id: this.patientId,
                active: 1
            };

            return apiClient.get('patient-insurances', filter, cancelToken);
        },
        loadSingle(apiClient, id) {
            return apiClient.get('patient-insurances/'+id);
        }
    },
    watch: {
        patientId: function () {
            this.fetchOptions('', true);
        }
    }
}
</script>
